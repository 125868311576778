import {
  Backdrop,
  Box,
  Collapse,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  Typography,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core";
import {
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  Note,
} from "@material-ui/icons";

import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import SearchBar from "material-ui-search-bar";
import AddWaterTesting from "./AddWaterTesting";
import UseTranslation from "../../services/UseTranslation";
import {
  ApplicationState,
  defaultWaterTesting,
  WaterTesting,
} from "../../types/Master";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import Tooltip from "@material-ui/core/Tooltip";
import {
  generateExcel,
  generatePDF,
  getFormatedDateForDisplay,
  getWaterTestRecotdType,
  getWaterTestTypes,
  getWaterTypes,
  getDeviceorSourceWater,
  getWaterTestPassFail
} from "../../services/Shared";
import { GetWaterTesting } from "../../services/ProductService";
import SideNav from "../shared/SideNav";
import { backgroundColor } from "html2canvas/dist/types/css/property-descriptors/background-color";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setShippingLabel: (val: string) =>
      dispatch({ type: "SET_SHIPPING_LABEL", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const headCells: HeadCell[] = [
  {
    id: "OperatoryNumber",
    numeric: false,
    disableSorting: false,
        label: "Operatory",
        sticky: true, stickyLeft: '0px' ,stickyWidth:'132px'
       
  },

  {
    id: "DateofSample",
    numeric: false,
    disableSorting: false,
      label: translation.dateofsample,
      sticky: true, stickyLeft: '132px', stickyWidth: '150px'
    },
    {
        id: "TestLabName",
        numeric: false,
        disableSorting: false,
        label: translation.test,
        sticky: true, stickyLeft: '282px', stickyWidth: '209px' 
        
    },
    {
        id: "ShippingLabel",
        numeric: false,
        disableSorting: true,
        label: "Dentisafe Shipping Label",
        sticky: true, stickyLeft: '491px', stickyWidth: '147px'
    },
    {
        id: "SampleFailed",
        numeric: false,
        disableSorting: false,
        label: "Pass/Fail",
        sticky: true, stickyLeft: '638px', stickyWidth: '101px'
        
    },
    {
        id: "WaterTestingResult",
        numeric: false,
        disableSorting: true,
        label: "Lab Result",      
       
    },
  {
    id: "WaterType",
    numeric: false,
    disableSorting: false,
    label: "Water Type",
  },
  {
    id: "DeviceOrSourceWater",
    numeric: false,
    disableSorting: false,
    label: "Device/Source Water",
  },
  {
    id: "TestType",
    numeric: false,
    disableSorting: false,
    label: translation.testtype,
  },
  {
    id: "RecordType",
    numeric: false,
    disableSorting: false,
    label: translation.recordType,
  },
 
  {
    id: "TesterName",
    numeric: false,
    disableSorting: false,
    label: "Tester's Name",
  },
  
  {
    id: "Image",
    numeric: false,
    disableSorting: true,
    label: translation.waterTestingImage,
  }, 
  
  {
    id: "Edit",
    numeric: false,
    disableSorting: true,
    label: translation.edit,
  },
];

const defaultcolumns = ["Operatory","Date of Sample", "Water Type","Device/Source Water","Test Type","Record Type", "Test","Tester's Name","Pass/Fail"];

const WaterTestings = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    isContactAccountsLoaded,
    setLoader,
    setToastMessage,
    selectedAccount,
    setShippingLabel,
  } = props;
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [waterTesting, setWaterTesting] = React.useState<WaterTesting[]>([]);
  const [totalWaterTesting, setTotalWaterTesting] = React.useState<
    WaterTesting[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searched, setSearched] = React.useState<string>("");
  const [updateWaterTesting, setUpdateWaterTesting] =
    React.useState<WaterTesting>(defaultWaterTesting);
  const [readOnly, setReadOnly] = React.useState<boolean>(false);
  const [isRetest, setIsRetest] = React.useState<boolean>(false);

  const [openRows, setOpenRows] = React.useState(new Map());
  const handleClick = (id: string) => {
    setOpenRows((prevOpenRows) => {
      const newOpenRows = new Map(prevOpenRows);
      newOpenRows.set(id, !newOpenRows.get(id));
      return newOpenRows;
    });
  };

  React.useEffect(() => {
    if (isContactAccountsLoaded && selectedAccount.ID != "") {
      getwaterTestingData();
    }
  }, [selectedAccount, isContactAccountsLoaded]);

  const getwaterTestingData = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setLoader(true);
      GetWaterTesting(selectedAccount.ID)
        .then((res) => {
          setWaterTesting(res);
          setTotalWaterTesting(res);
          setLoader(false);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          reject();
        });
    });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: WaterTesting[] = [];
    totalWaterTesting.forEach((item) => {
      let isAllWordsPresent = false;
      listOfWords.every((word: string) => {
        if (
          (item.TestType &&
            getWaterTestTypes(item.TestType)
              .toLowerCase()
              .includes(word.toLowerCase())) ||
              (item.WaterType &&
                getWaterTypes(item.WaterType)
                  .toLowerCase()
                  .includes(word.toLowerCase())) ||
                  (item.DeviceOrSourceWater &&
                    getDeviceorSourceWater(item.DeviceOrSourceWater)
                      .toLowerCase()
                      .includes(word.toLowerCase())) ||
                      (item.RecordType &&
                        getWaterTestRecotdType(item.RecordType)
                          .toLowerCase()
                          .includes(word.toLowerCase())) || 
                          (item.SampleFailed &&
                            getWaterTestPassFail(item.SampleFailed)
                              .toLowerCase()
                              .includes(word.toLowerCase())) ||
          (item.TestLabName &&
            item.TestLabName &&
            item.TestLabName.toLowerCase().includes(word.toLowerCase())) ||
            (item.TesterName &&
              item.TesterName &&
              item.TesterName.toLowerCase().includes(word.toLowerCase())) ||
              (item.OperatoryNumber &&
                item.OperatoryNumber &&
                item.OperatoryNumber.toLowerCase().includes(word.toLowerCase())) ||
                (item.TestLabNameDisplay &&
                  item.TestLabNameDisplay &&
                  item.TestLabNameDisplay.toLowerCase().includes(word.toLowerCase())) ||
          (item.DateofSample &&
            getFormatedDateForDisplay(item.DateofSample)
              .toLowerCase()
              .includes(word.toLowerCase()))
        ) {
          isAllWordsPresent = true;
          return true;
        } else {
          isAllWordsPresent = false;
          return false;
        }
      });
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setWaterTesting(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    const tableRows: any[] = [];
    waterTesting.forEach((test) => {
      const data = [
        test.OperatoryNumber,
        getFormatedDateForDisplay(test.DateofSample),
        getWaterTypes(test.WaterType),
        getDeviceorSourceWater(test.DeviceOrSourceWater),
        getWaterTestTypes(test.TestType),
        getWaterTestRecotdType(test.RecordType),
        test.TestLabName,
        test.TesterName,
        test.SampleFailed == "1" ? "Fail" : "Pass"
      ];
      tableRows.push(data);
    });
    const columnsizes = {
      0: { cellWidth: 19 }, // Adjust width of the first column
      1: { cellWidth: 22 }, 
      4:{ cellWidth: 20 },
      5:{ cellWidth: 20 },
      8:{ cellWidth: 20 },
    };
    if (isPdf) generatePDF(tableRows, defaultcolumns, translation.waterTesting,columnsizes);
    else generateExcel(tableRows, defaultcolumns, translation.waterTesting);
  };

  const handleViewAttachment = (urlPath: string) => {
    if (urlPath != "") {
      window.open(urlPath);
    } else {
      setToastMessage(translation.fileDoesnotExist, false);
    }
  };

  const handleEdit = (waterTesting: WaterTesting) => {
    setUpdateWaterTesting({ ...waterTesting });
    setReadOnly(false);
    setIsRetest(false);
  };
  const handleReTest = (waterTesting: WaterTesting) => {
    setUpdateWaterTesting({ ...waterTesting });
    setReadOnly(false);
    setIsRetest(true);
  };

  const handleView = (waterTesting: WaterTesting) => {
    setUpdateWaterTesting({ ...waterTesting });
    setReadOnly(true);
    setIsRetest(false);
  };

  const handleShippingLabel = (imageString: string) => {
    const linkSource = "data:image/png;base64," + imageString;
    setShippingLabel(linkSource);
    window.open("/viewshippinglabel");
  };

  const handleWaterResult = (resultUrl: any) => {
    if (resultUrl != null && resultUrl != "") window.open(resultUrl);
    else setToastMessage(translation.waterTestingResultDoesntExists, false);
  };

  const RecursiveChildren = (props: any) => {
    const { data } = props;
    const extension = null;

    return (
      <React.Fragment>
        {data.map((wt: WaterTesting) => (
          <React.Fragment key={wt.ID}>
            <TableRow>
                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '0px', zIndex: 9, backgroundColor: '#ffffff',width:'132px'  }}>
                {wt.ParentsWaterTestings.length > 0 ? (
                  <IconButton
                    className="mr-2 p-0"
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleClick(wt.ID)}
                  >
                    {openRows.get(wt.ID) ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                ) : (
                  <div className="sx-invisible-icon"                   
                  />
                )}
                {wt.OperatoryNumber}
                {wt.AdditionalNotes != null &&
                  wt.AdditionalNotes.length > 0 && (
                    <div>
                      <Tooltip title={wt.AdditionalNotes}>
                        <IconButton aria-label={wt.AdditionalNotes}>
                          <Note
                            fontSize="small"
                            className="tw-text-slate-400"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
              </TableCell>

                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '132px', zIndex: 9, backgroundColor: '#ffffff', width: '150px'}}>
                {wt.RecordType == "2" ? (
                  <IconButton
                    aria-label="refresh"
                    size="small"
                    className="sx-buttonIcon ml-1"
                  >
                    <Refresh fontSize="small" style={{fill:'#0072ea'}}/>
                  </IconButton>
                ):(
                  // Placeholder to maintain alignment
                  <div className="sx-invisible-icon"></div>
                )}

                {getFormatedDateForDisplay(wt.DateofSample)}
                    </TableCell>

                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '282px', zIndex: 9, backgroundColor: '#ffffff', width:'209px' }}>
                        {wt.TestLabNameDisplay}
                        {wt.DentisafeTesting && <div> Test ID : {wt.TestId}</div>}
                    </TableCell>
                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '491px', zIndex: 9, backgroundColor: '#ffffff', width: '147px' }}>
                        <Typography>
                            {wt.DentisafeTesting && (
                                <a
                                    onClick={() => handleShippingLabel(wt.ShippingLabel)}
                                    className="sx-blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ></a>
                            )}
                        </Typography>
                    </TableCell>
                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '638px', zIndex: 9, backgroundColor: '#ffffff', width: '101px' }} >
                        <Box className="!tw-flex tw-items-center">
                            {wt.DentisafeTesting ? (
                                <div>See 'Water Test Result'</div>
                            ) : (
                                <div
                                    className={
                                        wt.SampleFailed == "1" ? "text-danger" : "text-success"
                                    }
                                >
                                    {wt.SampleFailed == "1" ? "Fail" : "Pass"}
                                </div>
                            )}
                        </Box>
                    </TableCell>

                    <TableCell className="!tw-border-b-0" >
                        <Typography>
                            {wt.DentisafeTesting && (
                                <a
                                    onClick={() =>
                                        handleWaterResult(wt.WaterTestingReportPath)
                                    }
                                    className="sx-blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={require("../../images/pdf-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid" 
                                        style={{ height: '25px' }}
                                    />
                                </a>
                            )}
                        </Typography>
                    </TableCell>
              <TableCell className="!tw-border-b-0">
                {getWaterTypes(wt.WaterType)}
              </TableCell>
              <TableCell className="!tw-border-b-0">
                {getDeviceorSourceWater(wt.DeviceOrSourceWater)}
              </TableCell>
              <TableCell className="!tw-border-b-0">
                {getWaterTestTypes(wt.TestType)}
              </TableCell>
              <TableCell className="!tw-border-b-0">
                {getWaterTestRecotdType(wt.RecordType)}
              </TableCell>
             
              <TableCell className="!tw-border-b-0">{wt.TesterName}</TableCell>
             

              <TableCell className="!tw-border-b-0">
                <Typography>
                  {wt.AttachmentPath != null &&
                    wt.AttachmentPath.length > 0 && (
                      <a
                        onClick={() => handleViewAttachment(wt.AttachmentPath)}
                        className="sx-blue"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    )}
                </Typography>
              </TableCell>

             

              

              <TableCell className="!tw-border-b-0">
                {!wt.DentisafeTesting && (
                  <Box className="d-flex align-items-center">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      className="sx-buttonIcon ml-1"
                      onClick={() => handleEdit(wt)}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </TableCell>
            </TableRow>
            {wt.ParentsWaterTestings.length > 0 && openRows.get(wt.ID) ? (
              <RecursiveChildren data={wt.ParentsWaterTestings} />
            ) : null}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={0}>
      {/*<Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">*/}
      {/*  <SideNav />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} sm={9} md={10}>*/}
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <Box className="p-3">
          <h3>{translation.waterTesting}</h3>
          <p className="small">
            {translation.waterTestingDetails}{" "}
            <a
              href="https://solmetex.com/wp-content/uploads/2024/04/FC15-TestingLog.pdf"
              className="text-danger"
              target="_blank"
              rel="noopener noreferrer"
            >
             click here.
            </a>           
          </p>
        </Box>
          <Box className="sx-table-shadow mb-5 p-3" style={{ width: '100%' }}>
          <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              className="col-sm-5 px-0 sx-tableSearch shadow-none"
            />
            <Box className="d-flex justify-content-between align-items-center pt-1">
              <AddWaterTesting
                getwaterTestingData={getwaterTestingData}
                updateWaterTesting={updateWaterTesting}
                readOnlyMode={readOnly}
                setReadOnlyMode={setReadOnly}
                reTest={isRetest}
              />
              <IconButton
                className="ml-3 p-0"
                onClick={() => downloadExcelOrPdf(true)}
              >
                <img
                  src={require("../../images/pdf.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
              <IconButton
                className="p-0"
                onClick={() => downloadExcelOrPdf(false)}
              >
                <img
                  src={require("../../images/xls.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
            </Box>
          </Box>
          <Box className="pb-3">
            <p className="small">
              <IconButton
                aria-label="refresh"
                size="small"
                className="sx-buttonIcon ml-1"
              >
                <Refresh fontSize="small" style={{fill:'#0072ea'}}/>
              </IconButton>
              Icon indicates a Retest result, after a failure has occured.
              <IconButton>
                <Note fontSize="small" className="tw-text-slate-400" />
              </IconButton>
              Mouse over the icon to view user-added notes.
            </p>
                  <TableContainer className="scrollable-table">
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  rowCount={waterTesting.length}
                  displayActionCell={false}
                  onRequestSort={handleRequestSort}
                />
                {waterTesting.length == 0 ? (
                  <TableBody className="sx-tableBody">
                    <TableRow>
                      <TableCell
                        className="mb-5 p-3 text-center"
                        colSpan={headCells.length}
                      >
                        <img
                          src={require("../../images/empty-state.png")}
                          alt="Solmetex"
                          className="img-fluid tw-mx-auto"
                        />
                        <h3 className="mt-3 sx-blue">No Data Found</h3>
                        <p>please try again later</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="sx-tableBody">
                    {stableSort(waterTesting, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((wt) => {
                        let extension = wt.AttachmentPath.split(".").pop();
                        return (
                          <React.Fragment>
                            <TableRow>
                                    <TableCell className="!tw-border-b-0" style={{ position: 'sticky', left: '0px', zIndex: 9, backgroundColor: '#ffffff', width:'132px' }}>
                                {wt.ParentsWaterTestings.length > 0 ? (
                                  <IconButton
                                    className="mr-2 p-0"
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => handleClick(wt.ID)}
                                  >
                                    {openRows.get(wt.ID) ? (
                                      <KeyboardArrowUp />
                                    ) : (
                                      <KeyboardArrowDown />
                                    )}
                                  </IconButton>
                                ) : (
                                  // Placeholder to maintain alignment
                                  <div className="sx-invisible-icon"></div>
                                )}
                                {wt.OperatoryNumber}
                                {wt.AdditionalNotes != null &&
                                  wt.AdditionalNotes.length > 0 && (
                                    <span>
                                      <Tooltip title={wt.AdditionalNotes}>
                                        <IconButton
                                          aria-label={wt.AdditionalNotes}
                                        >
                                          <Note
                                            fontSize="small"
                                            className="tw-text-slate-400"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </span>
                                  )}
                              </TableCell>

                                    <TableCell className="!tw-border-b-0 !tw-flex tw-items-center tw-w-[150px]" style={{ position: 'sticky', left: '132px', zIndex: 9, backgroundColor: '#ffffff', width: '150px'  }}>
                                {wt.RecordType == "2" ? (
                                  <IconButton
                                    aria-label="refresh"
                                    size="small"
                                    className="sx-buttonIcon ml-1"
                                  >
                                    <Refresh fontSize="small" style={{fill:'#0072ea'}}/>
                                  </IconButton>
                                ):(
                                  // Placeholder to maintain alignment
                                  <div className="sx-invisible-icon"></div>
                                )}

                                {getFormatedDateForDisplay(wt.DateofSample)}
                                    </TableCell>
                                    <TableCell
                                        className="!tw-border-b-0"
                                        style={{
                                            position: 'sticky',
                                            left: '282px',
                                            zIndex: 9,
                                            backgroundColor: '#ffffff',
                                            width: '209px',
                                        }}
                                    >
                                        {wt.TestLabNameDisplay ? (
                                            <>
                                                {wt.TestLabNameDisplay}
                                                {wt.DentisafeTesting && <div>Test ID : {wt.TestId}</div>}
                                            </>
                                        ) : (
                                            // Render an empty div with a fixed width
                                            <div style={{ width: '241px', visibility: 'hidden' }}>&nbsp;</div>
                                        )}
                                    </TableCell>

                                    <TableCell className="!tw-border-b-0" style={{
                                        position: 'sticky',
                                        left: '491px',
                                        zIndex: 9,
                                        backgroundColor: '#ffffff',
                                        width: '147px',
                                    }}>
                                        <Typography>
                                            {wt.DentisafeTesting && (
                                                <a
                                                    onClick={() =>
                                                        handleShippingLabel(wt.ShippingLabel)
                                                    }
                                                    className="sx-blue"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {extension &&
                                                        extension.toLowerCase() == "pdf" ? (
                                                        <img
                                                            src={require("../../images/pdf-download.png")}
                                                            alt="Solmetex"
                                                            className="img-fluid"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={require("../../images/img-download.png")}
                                                            alt="Solmetex"
                                                            className="img-fluid"
                                                        />
                                                    )}
                                                </a>
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className="!tw-border-b-0"
                                        style={{ position: 'sticky', left: '638px', zIndex: 9, backgroundColor: '#ffffff', width: '101px' }}
                                    >
                                        <Box className="!tw-flex tw-items-center">
                                            {wt.DentisafeTesting ? (
                                                <div>See 'Lab Result'</div>
                                            ) : (
                                                <div
                                                    className={
                                                        wt.SampleFailed === "1"
                                                            ? "text-danger"
                                                            : "text-success"
                                                    }
                                                >
                                                    {wt.SampleFailed === "1" ? "Fail" : "Pass"}
                                                </div>
                                            )}

                                            {/* Render button conditionally */}
                                            {!wt.DentisafeTesting && (
                                                <Button
                                                    variant="contained"
                                                    className={`ml-2 sx-button p-0 ${wt.SampleFailed === "1" ? "" : "hidden-button"
                                                        }`}
                                                    onClick={(e) => handleReTest(wt)}
                                                    style={{
                                                        visibility: wt.SampleFailed === "1" ? "visible" : "hidden",
                                                    }}
                                                >
                                                    <span className="sx-hideOnMobile">
                                                        + Add Retest
                                                    </span>
                                                </Button>
                                            )}
                                        </Box>
                                    </TableCell>

                                    <TableCell className="!tw-border-b-0" >
                                        <Typography>
                                            {wt.DentisafeTesting && (
                                                <a
                                                    onClick={() =>
                                                        handleWaterResult(
                                                            wt.WaterTestingReportPath
                                                        )
                                                    }
                                                    className="sx-blue"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={require("../../images/pdf-download.png")}
                                                        alt="Solmetex"
                                                        className="img-fluid"
                                                        style={{height:'36px',width:'36px'}}
                                                    />
                                                </a>
                                            )}
                                        </Typography>
                                    </TableCell>
                              <TableCell className="!tw-border-b-0">
                                {getWaterTypes(wt.WaterType)}
                              </TableCell>
                              <TableCell className="!tw-border-b-0">
                                {getDeviceorSourceWater(wt.DeviceOrSourceWater)}
                              </TableCell>
                              <TableCell className="!tw-border-b-0">
                                {getWaterTestTypes(wt.TestType)}
                              </TableCell>
                              <TableCell className="!tw-border-b-0">
                                {getWaterTestRecotdType(wt.RecordType)}
                              </TableCell>
                             
                              <TableCell className="!tw-border-b-0">
                                {wt.TesterName}
                              </TableCell>
                             

                              <TableCell className="!tw-border-b-0">
                                <Typography>
                                  {wt.AttachmentPath != null &&
                                    wt.AttachmentPath.length > 0 && (
                                      <a
                                        onClick={() =>
                                          handleViewAttachment(
                                            wt.AttachmentPath
                                          )
                                        }
                                        className="sx-blue"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {extension &&
                                        extension.toLowerCase() == "pdf" ? (
                                          <img
                                            src={require("../../images/pdf-download.png")}
                                            alt="Solmetex"
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <img
                                            src={require("../../images/img-download.png")}
                                            alt="Solmetex"
                                            className="img-fluid"
                                          />
                                        )}
                                      </a>
                                    )}
                                </Typography>
                              </TableCell>

                                                     

                              <TableCell className="!tw-border-b-0">
                                {!wt.DentisafeTesting && (
                                  <Box className="d-flex align-items-center">
                                    <IconButton
                                      aria-label="edit"
                                      size="small"
                                      className="sx-buttonIcon ml-1"
                                      onClick={() => handleEdit(wt)}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Box>
                                )}
                              </TableCell>
                            </TableRow>

                            {/* parent items Component  Here */}
                            {wt.ParentsWaterTestings && openRows.get(wt.ID) ? (
                              // <Collapse
                              //       in={openRows.get(wt.ID)}
                              //       timeout="auto"
                              //       unmountOnExit
                              //     >
                              <RecursiveChildren
                                data={wt.ParentsWaterTestings}
                              />
                            ) : // </Collapse>
                            null}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={waterTesting.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination-content"
            />
          </Box>
        </Box>
    {/*  </Grid>*/}
    </Grid>
  );
};

export default connector(WaterTestings);
