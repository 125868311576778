import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import UseTranslation from "../../../services/UseTranslation";
import {
  ApplicationState,
  Vial,
  WaterTesting,
  defaultWaterTesting,
} from "../../../types/Master";
import { Alert, Container, Navbar, NavLink } from "reactstrap";
import DSStep1Form from "./DSStep1Form";
import DSStep2Form from "./DSStep2Form";
import { Close } from "@material-ui/icons";
import { AnyAction, Dispatch } from "redux";
import internal from "events";
import { AnyARecord } from "dns";
import { CreateWaterTestingDS } from "../../../services/ProductService";

interface errorValidation {
  testID:string;
}

const defaultErrorValidation = {
  testID:""
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  selectedAccount: state.SelectedAccount,
  user: state.user,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const translation = UseTranslation();

const DSLandingPage = (props: PropsFromRedux) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    selectedAccount,
    user,
    selectedAccountContact,
  } = props;
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [step, setStep] = React.useState(1);
  const [testID, setTestID] = React.useState("");
  const history = useHistory();
  const [showDSPopup, setShowDSPopup] = React.useState<boolean>(false);
  const [showTestIDRequired, setShowTestIDRequired] =
    React.useState<boolean>(false);
  const [waterTestingDataRequest, setWaterTestingDataRequest] =
    React.useState<WaterTesting>(defaultWaterTesting);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  const id = useParams();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (window.location.pathname.toLowerCase() == "/dentisafetesting") {
      var dentisafeId = searchParams.get("test_id");
      if (dentisafeId != null && dentisafeId.length > 0) {
        var id = localStorage.getItem("dentisafeId");
        if (id == null) {
          localStorage.setItem("dentisafeId", dentisafeId);
          history.push(`${window.location.pathname}?test_id=${testID}`);
        } else {
          localStorage.removeItem("dentisafeId");
          localStorage.setItem("dentisafeId", dentisafeId);
        }
      }
      //   else {
      //       var id = localStorage.getItem("dentisafeId");
      //       if (id != null) {
      //           localStorage.removeItem("dentisafeId");
      //       }
      //   }
    }
    let dsId = localStorage.getItem("dentisafeId");
    if (dsId == null || dsId.length == 0) {
      setTestID("");
      setShowDSPopup(true);
    }
  }, [selectedAccount]);

  React.useEffect(() => {
    let dsrequest = localStorage.getItem("dentisaferequest");
    if (dsrequest != null) {
      var dsRequest = JSON.parse(dsrequest);
      setWaterTestingDataRequest(dsRequest);
    }
  }, [selectedAccount]);

  React.useEffect(() => {
   setWaterTestingDataRequest(defaultWaterTesting);
  }, [selectedAccountContact]);

  const handleTestIDSubmit = () => {
    if(errorValidationForTestID()){
      localStorage.setItem("dentisafeId", testID);
      history.push(`${window.location.pathname}?test_id=${testID}`);
      setShowDSPopup(false);
      setStep(1);
    } else {
      setTestID("");
      setShowDSPopup(true);
    }
  };


  const errorValidationForTestID = (): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (testID == null || testID.length==0) {
      setShowTestIDRequired(true);
      errorsObject = { ...errorsObject, testID: translation.requiredField };
      isValid = false;
    }
    else{
      setShowTestIDRequired(false);
    }    
    setErrors({ ...errorsObject });
    return isValid;
  };
  

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var ContactID = user.ID;
    var email=user.Email;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      ContactID = selectedAccountContact.ID;
      email=selectedAccountContact.Email;
    }
    //if (errorValidation(waterTestingDataRequest)) {
    setLoader(true);

    waterTestingDataRequest.ID = waterTestingDataRequest.ID;
    waterTestingDataRequest.RequestContact = "0";
    waterTestingDataRequest.SubmittedBy = ContactID;
    waterTestingDataRequest.ContactEmail=email;
    if (waterTestingDataRequest.DateofSample == "") {
      waterTestingDataRequest.DateofSample = "0001-01-01";
    }

    if (waterTestingDataRequest.InstallationDate == "") {
      waterTestingDataRequest.InstallationDate = "0001-01-01";
    }
    waterTestingDataRequest.Customer = selectedAccount;
    var id = localStorage.getItem("dentisafeId");
    if (id != null) {
      waterTestingDataRequest.TestID = id.toString();
    }

    CreateWaterTestingDS(waterTestingDataRequest)
      .then((res:any) => {      
        if(res.success){
          localStorage.removeItem("dentisafeId");
          localStorage.removeItem("dentisaferequest");
          setLoader(false);
          setWaterTestingDataRequest({ ...defaultWaterTesting });
          setToastMessage("Water testing request created", true);
          history.push("/watertesting");
        }
        else if(res.message!=null && res.message!=""){ 
          setLoader(false);

          setToastMessage(res.message, false);
          //console.error(res.message);
        }
        else{
          setLoader(false);

        setToastMessage(translation.watertTestingFailure, false);
        //console.error(ex);
        }
      })
      .catch((ex) => {
        // setWaterTestingDataRequest({ ...defaultWaterTesting });

        setLoader(false);

        setToastMessage(translation.watertTestingFailure, false);
        console.error(ex);
      });
    //}
  };

  const handleClose = () => {
    const shouldClose = window.confirm(
      "Are you sure you don't want to enter test id? Please scan and start the process later if you cancel."
    );

    if (shouldClose) {
      localStorage.removeItem("dentisafeId");
      setShowDSPopup(false);
      history.push("/home");
    }
  };

  const handleFormChange = (e: any) => {};

  const handleStepButton = (stepNumber: any) => {
    var step = Number(stepNumber);
    setStep(stepNumber);
  };

  return (
    <React.Fragment>
      {showDSPopup ? (
        <Dialog open={showDSPopup} onClose={handleClose}>
          <DialogTitle>{translation.testIDmissing}</DialogTitle>
          <Box position="absolute" top={18} right={18}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText>Please enter Test ID.</DialogContentText>
            <Box>
              <TextField
                className={`sx-inputGroup ${
                  showTestIDRequired && "sx-validationFailed"
                }`}
                margin="normal"
                fullWidth
                id="testid"
                name="testid"
                type="text"
                label="Test ID"
                value={testID} // Set the value from state
                onChange={(e) => setTestID(e.target.value)} // Update state on change
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 1 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
              {showTestIDRequired && (
                <div className="error-text font-10 pl-4 text-left">
                  Required
                </div>
              )}
            </Box>
          </DialogContent>
          <DialogActions className="!tw-mr-4 !tw-mb-4">
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="sx-button !tw-rounded-full !tw-bg-[#008cc1] !tw-text-white !tw-min-w-[100px]"
              onClick={handleTestIDSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <React.Fragment>
          <header>
            <Navbar
              className={
                isMobile
                  ? "navbar-expand-sm navbar-toggleable-sm box-shadow d-flex justify-content-center mb-xs-3 position-fixed"
                  : "navbar-expand-sm navbar-toggleable-sm box-shadow d-flex justify-content-center mb-xs-3 py-1"
              }
              light
            >
              <NavLink tag={Link} className="sx-headerLogo" to="/">
                <img
                  src={require("../../../images/dentisafe.png")}
                  alt="Solmetex"
                  className="img-fluid"
                />
              </NavLink>
            </Navbar>
          </header>
          <Container className="tw-pb-6">
            <Box className="mt-4 col-12 col-md-10 mx-auto">
              <h3 className="!tw-text-4xl tw-font-extrabold">
                Dental Unit Waterline Test Submission
              </h3>
              <Box className="tw-mt-3 col-12 col-md-6">
                <ol className="tw-flex tw-items-center tw-w-full tw-text-sm tw-font-medium text-center tw-text-gray-500">
                  <li className="tw-flex md:tw-w-full tw-items-center tw-text-emerald-500 sm:after:content-[''] after:tw-w-full after:tw-h-1 after:tw-border-b after:tw-border-gray-400 after:tw-border-1 after:tw-hidden sm:after:tw-inline-block after:tw-mx-6 xl:after:tw-mx-7 ">
                    <span className="tw-flex tw-items-center after:content-['/'] sm:after:tw-hidden after:tw-mx-2 after:tw-text-gray-200">
                      <svg
                        className="tw-w-3.5 tw-h-3.5 sm:tw-w-4 sm:tw-h-4 tw-me-1.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      STEP{" "}
                      <span className="tw-hidden sm:tw-inline-flex sm:tw-ms-2">
                        1
                      </span>
                    </span>
                  </li>
                  <li
                    className={`tw-flex tw-items-center ${
                      step === 2 && "tw-text-emerald-500"
                    }`}
                  >
                    <span className="tw-flex tw-items-center after:content-['/'] sm:after:tw-hidden after:tw-mx-2 after:tw-text-gray-200">
                      {step === 2 && (
                        <svg
                          className="tw-w-3.5 tw-h-3.5 sm:tw-w-4 sm:tw-h-4 tw-me-1.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                      )}
                      STEP{" "}
                      <span className="tw-hidden sm:tw-inline-flex sm:tw-ms-2">
                        2
                      </span>
                    </span>
                  </li>
                  {/* <li className="tw-flex tw-items-center">CONFIRMATION</li> */}
                </ol>
              </Box>
              {/* <p className="small">{translation.recycledCertificatesDetails}</p> */}
              {step === 1 && (
                <p className="small tw-mt-8 tw-mb-2 tw-text-gray-400 tw-pl-4">
                  If you would like to update the address information below in
                  our portal, please reach out to Solmetex Customer Service at
                  800-216-5505
                </p>
              )}
              <Box
                className={`tw-p-4 tw-mb-8 tw-px-6 tw-rounded-t-lg tw-overflow-hidden tw-border tw-border-gray-200 tw-sm-p-10 tw-shadow-sm ${
                  step === 2 && "tw-mt-8"
                }`}
              >
                {step === 1 && (
                  <DSStep1Form
                    waterTetsingRequest={waterTestingDataRequest}
                    handleFormChange={handleFormChange}
                    handleStepButton={handleStepButton}
                    setWaterTestingDataRequest={setWaterTestingDataRequest}
                  />
                )}
                {step === 2 && (
                  <DSStep2Form
                    waterTetsingRequest={waterTestingDataRequest}
                    handleStepButton={handleStepButton}
                    handleSubmit={handleSubmit}
                    setWaterTestingDataRequest={setWaterTestingDataRequest}
                  />
                )}
              </Box>
            </Box>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connector(DSLandingPage);
