import * as React from "react";
import {
  Box,
  Dialog,
  FormControl,
  MenuItem,
  Select,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Snackbar,
  IconButton,
  Button,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import {
  Account,
  ApplicationState,
  ComplianceStatus,
  LoggedInRole,
  User,
  defaultAccount,
} from "../../types/Master";
import UseTranslation from "../../services/UseTranslation";
import { Alert, Navbar, NavLink } from "reactstrap";
import { Dispatch } from "redux";
import { action_types } from "../../types/ActionTypes";
import { Close, CheckBox, Menu } from "@material-ui/icons";
import { Tabs } from "../../services/Shared";
import {
  GetAccountsWithNationalManagerSelectedAccount,
  GetContactWithAccounts,
  UpdateAccountLastLogin,
  GetContactOfAccountByContactEmail,
} from "../../services/CustomerService";
import "../../custom.css";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";
import profileIcon from "../../images/profileIcon.svg";

const translation = UseTranslation();
const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isAuthUser: state.isAuthUser,
  selectedAccount: state.SelectedAccount,
  setSelectedAccountContact: state.SelectedAccountContact,
  toast: state.toast,
  showSelectedAccountData: state.ShowSelectedAccountData,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  selectedTab: state.SelectedTab,
  isWooCommerceRedirectionRequired: state.WooCommerceRedirectionRequired,
  dsoParentAccount: state.DsoParentAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setSelectedAccount: (val: Account) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: val }),
    setSelectedAccountContact: (val: User) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT_CONTACT", payload: val }),
    setShowSelectedAccountData: (val: boolean) =>
      dispatch({ type: "SET_SHOW_SELECTED_ACCOUNT_DATA", payload: val }),
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setContact: (val: User) => dispatch({ type: "SET_CONTACT", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setIsContactAccountsLoaded: (val: boolean) =>
      dispatch({ type: "SET_IS_CONTACT_ACCOUNTS_LOADED", payload: val }),
    setSelectedTab: (val: number) =>
      dispatch({ type: "SET_SELECTED_TAB", payload: val }),
    setDisplayAccountsData: (val: boolean) =>
      dispatch({ type: "SET_DISPLAY_ACCOUNTS_DATA", payload: val }),
    setDsoDetails: (val: {
      DsoAccounts: Account[];
      DsoParentAccount: Account;
    }) => dispatch({ type: "SET_DSO_DETAILS", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const useOutsideAlerter = (ref: any, handleClear: () => void) => {
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClear();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const NavMenu = (props: PropsFromRedux) => {
  const {
    isAuthUser,
    user,
    selectedAccount,
    toast,
    showSelectedAccountData,
    isContactAccountsLoaded,
    selectedTab,
    isWooCommerceRedirectionRequired,
    dsoParentAccount,
    setShowSelectedAccountData,
    setSelectedAccount,
    setToastMessage,
    logout,
    setContact,
    setLoader,
    setSelectedTab,
    setIsContactAccountsLoaded,
    setDisplayAccountsData,
    setDsoDetails,
    setSelectedAccountContact,
  } = props;
  const [openSelectAccount, setOpenSelectAccount] =
    React.useState<boolean>(false);
  const [openMyAccount, setOpenMyAccount] = React.useState<boolean>(false);
  const wrapperRef = React.useRef(null);
  const history = useHistory();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [isClearDsID, setIsClearDsID] = React.useState<boolean>(false);

  React.useEffect(() => {
    const pathname = window.location.pathname;
    Tabs.every((item) => {
      if (pathname == item.url) {
        setSelectedTab(item.id);
        return false;
      } else return true;
    });
  }, [showSelectedAccountData]);

  React.useEffect(() => {
    if (!isContactAccountsLoaded && user.ID != "") {
      setLoader(true);
      GetContactWithAccounts(user.ID)
        .then((user: User) => {
          setLoader(false);
          setContact(user);
          setIsContactAccountsLoaded(true);
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
        });
    }
  }, [isAuthUser]);

  const handleMyAccountClear = () => {
    setOpenMyAccount(false);
  };

  useOutsideAlerter(wrapperRef, handleMyAccountClear);

  const updateSelectAccount = (account: Account) => {
    setSelectedAccount({ ...account });
    UpdateAccountLastLogin(account.ID, user.ID).catch((ex) =>
      console.error(ex)
    );

    setOpenSelectAccount(false);
  };

  React.useEffect(() => {    
    if (
      selectedAccount &&
      selectedAccount.Number != "" &&
      user.Accounts != null &&
      user.Accounts.length > 1
    ) {
      getSelectedAccountContactByContactEmail();
    } else {
      if (user != null && user.ID != "") {
        setSelectedAccountContact(user);
      }
    }  
    
  }, [selectedAccount]);

  React.useEffect(() => {
    if (user.Accounts.length > 0 && selectedAccount.ID == "") {
      setIsClearDsID(false);
    } else {
      setIsClearDsID(true);
    }
  }, [selectedAccount]);

  const getSelectedAccountContactByContactEmail = (): Promise<void> => {
    setLoader(true);    
    return new Promise((resolve, reject) => {
      GetContactOfAccountByContactEmail(selectedAccount.ID, user.Email)
        .then((res: any) => {          
          setSelectedAccountContact(res);
          resolve();
          setLoader(false);
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          reject();
        });
    });
  };

  React.useEffect(() => {
    if (
      user.Accounts &&
      user.Accounts.length > 1 &&
      (user.Role == LoggedInRole.Regional ||
        user.Role == LoggedInRole.SubRegional ||
        (user.Role == LoggedInRole.National &&
          !user.IsLoginForMultipleAccounts))
    ) {
      setDsoDetails({
        DsoParentAccount: {
          ...defaultAccount,
          ID: user.Account.ID,
          Name: user.Account.Name,
        },
        DsoAccounts: [...user.Accounts],
      });
      updateSelectAccount({
        ...defaultAccount,
        ID: user.Account.ID,
        Name: user.Account.Name,
      });
    } else if (
      user.Accounts &&
      user.Accounts.length == 1 &&
      selectedAccount.ID == ""
    ) {
      updateSelectAccount(user.Accounts[0]);
    }
  }, [user.Accounts]);

  const handleAccountSelection = (e: any) => {
    user.Accounts.length > 0 &&
      user.Accounts.every((item) => {
        if (item.ID == (e.target.value as string)) {
          updateSelectAccount(item);
          localStorage.removeItem("dentisaferequest");
          if (isClearDsID) {
            localStorage.removeItem("dentisafeId");
          }
          return false;
        } else return true;
      });
  };

  React.useEffect(() => {
    if (
      selectedAccount.ID != "" &&
      user.Role == LoggedInRole.National &&
      user.IsLoginForMultipleAccounts &&
      user.Accounts.length > 0 &&
      !showSelectedAccountData
    ) {
      setLoader(true);
      GetAccountsWithNationalManagerSelectedAccount(selectedAccount.ID)
        .then((accounts) => {
          setDsoDetails({
            DsoParentAccount: { ...selectedAccount },
            DsoAccounts: [...accounts],
          });
          setLoader(false);
        })
        .catch((ex) => {
          setLoader(false);
          console.error(ex);
        });
    }
  }, [selectedAccount]);

  const handleClose = () => {
    setOpenSelectAccount(false);
  };

  const handleSnackbarClose = () => {
    setToastMessage("", false);
  };

  const handleLogout = () => {
    console.log("clicked");
    handleMyAccountClear();
    logout();
    localStorage.removeItem("dentisafeId");
    localStorage.removeItem("dentisaferequest");
  };

  const handleNavigation = (val: string) => {
    if (val.includes("dashboard")) {
      setDisplayAccountsData(false);
      setSelectedAccount({ ...dsoParentAccount });
    } else setDisplayAccountsData(true);
    if (showSelectedAccountData) {
      setShowSelectedAccountData(false);
      history.push(val);
    } else {
      history.push("/");
    }
  };

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <>
      <Snackbar
        open={toast.Message != ""}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className="snackbar">
          <Alert
            color={
              toast.Message != "" ? (toast.Status ? "success" : "danger") : ""
            }
            toggle={handleSnackbarClose}
          >
            {toast.Status ? <CheckBox className="mr-3" /> : <Close />}
            {toast.Message}
          </Alert>
        </Box>
      </Snackbar>
      {isWooCommerceRedirectionRequired ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          {isAuthUser ? (
            <header>
              <Navbar
                className={
                  isMobile
                    ? "navbar-expand-sm navbar-toggleable-sm box-shadow d-flex justify-content-between mb-xs-3 position-fixed"
                    : "navbar-expand-sm navbar-toggleable-sm box-shadow d-flex justify-content-between mb-xs-3"
                }
                light
              >
                <NavLink tag={Link} className="sx-headerLogo" to="/">
                  <img
                    src={require("../../images/Solmetex-Logo.png")}
                    alt="Solmetex"
                    className="img-fluid"
                  />
                </NavLink>
                {/* <Box className="sx-hideOnDesktop d-flex align-items-center"> */}
                  {/* {isAuthUser && (
                    <Box className="dropdown">
                      <Box className="mr-4 sx-border-right">
                        <Box
                          onClick={() => setOpenMyAccount(!openMyAccount)}
                          className="sx-header-userAvatar mr-4 d-flex justify-content-center align-items-center"
                        >
                          <img
                            src={profileIcon}
                            alt="Solmetex"
                            className="img-fluid"
                          />
                        </Box>
                      </Box>
                      {openMyAccount && (
                        <div
                          ref={wrapperRef}
                          className="sx-myAccount flex-column d-flex"
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="small small py-2 px-3">
                              {selectedAccount.ID != ""
                                ? `${translation.accountNumber} : ${selectedAccount.Number}`
                                : ""}
                            </span>

                            <div >
                            <Button
                              className="btn btn-default sx-logoutBtn test"
                              onClick={handleLogout}
                            >
                              {translation.logOut}
                            </Button>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-2 mb-4 px-3">
                            <div className="sx-loginAccountName mr-3">
                              {`${user.FirstName.charAt(
                                0
                              )}${user.LastName.charAt(0)}`}
                            </div>
                            <div className="d-flex flex-column">
                              <span className="sx-lh-initial font-weight-bold">
                                {`${user.FirstName} ${user.LastName}`}
                              </span>
                              <span className="small sx-lh-initial">
                                {user.Email}
                              </span>
                              <Link
                                className="small mt-1 sx-underLine"
                                to="/changepassword"
                              >
                                {translation.changePassword}
                              </Link>
                            </div>
                          </div>
                          {user.IsLoginForMultipleAccounts &&
                            user.Accounts.length > 1 && (
                              <Link
                                className="sx-switchAccount small"
                                to="#"
                                onClick={() => setOpenSelectAccount(true)}
                              >
                                <img
                                  src={require("../../images/changes.png")}
                                  alt="Solmetex"
                                  className="img-fluid mr-2 sx-chnageIcon"
                                />
                                {translation.changeAccount}
                              </Link>
                            )}
                        </div>
                      )}
                    </Box>
                  )} */}
                  {/* <IconButton aria-label="menu" onClick={handleToggle}>
                    {isMobile ? <Close /> : <Menu />}
                  </IconButton>
                </Box> */}
                {isContactAccountsLoaded && (
                  <Dialog
                    className="sx-model"
                    open={
                      (isAuthUser && selectedAccount.ID == "") ||
                      openSelectAccount
                    }
                    onClose={handleClose}
                  >
                    <DialogTitle className="sx-model-title">
                      {translation.selectAccount}
                    </DialogTitle>
                    <DialogContent className="my-4">
                      <DialogContentText>
                        {translation.selectAccountInstructions}
                      </DialogContentText>
                      <FormControl
                        fullWidth
                        variant="filled"
                        className="sx-inputGroup sx-customSelect my-2"
                      >
                        <Select
                          id="select-account"
                          value={
                            user.IsDsoLogin
                              ? dsoParentAccount.ID
                              : selectedAccount.ID
                          }
                          onChange={handleAccountSelection}
                          className="sx-input"
                          disableUnderline={true}
                          displayEmpty
                        >
                          <MenuItem value="" className="disabled">
                            {translation.selectAccount}
                          </MenuItem>
                          {user.Accounts &&
                            user.Accounts.length > 0 &&
                            user.Accounts.map((item) => {
                              return (
                                <MenuItem value={item.ID}>{item.Name}</MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </DialogContent>
                  </Dialog>
                )}
                {isAuthUser && (
                  <Box className="d-flex flex-column">
                    <Box className="d-flex align-items-center">
                      {user.IsDsoLogin && (
                        <Box className="d-flex align-items-center sx-hideOnMobile">
                          <Link
                            className="btn btn-link"
                            to="#"
                            onClick={() => {
                              handleNavigation("/dashboard");
                            }}
                          >
                            {translation.dashboard}
                          </Link>
                          {showSelectedAccountData && (
                            <Link
                              className="btn btn-link"
                              to="#"
                              onClick={() => {
                                handleNavigation("/accounts");
                              }}
                            >
                              {translation.accounts}
                            </Link>
                          )}
                        </Box>
                      )}
                      {(!user.IsDsoLogin || showSelectedAccountData) &&
                        selectedAccount.ID != "" && (
                          <Box className="d-flex align-items-center sx-header-myAccount ml-3 sx-hideOnMobile">
                            <Box className="flex-column d-flex ml-2 text-center">
                              <span
                                title={selectedAccount.Name}
                                className="sx-lh-initial d-inline-block text-truncate sx-header-myAccountName"
                              >
                                {selectedAccount.Name}
                              </span>
                            </Box>
                          </Box>
                        )}
                        
                      <Box className="dropdown">
                        <Box className="tw-border-[#D1D1D1] lg:tw-ml-4 lg:tw-border-l">
                          <Box
                            onClick={() => setOpenMyAccount(!openMyAccount)}
                            className="sx-header-userAvatar tw-ml-4 d-flex justify-content-center align-items-center"
                          >
                            <img
                              src={profileIcon}
                              alt="Solmetex"
                              className="img-fluid"
                            />
                          </Box>
                        </Box>
                        {openMyAccount && (
                          <div
                            ref={wrapperRef}
                            className="sx-myAccount flex-column d-flex tw-mr-[-20px]"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="small small py-2 px-3">
                                {selectedAccount.ID != ""
                                  ? `${translation.accountNumber} : ${selectedAccount.Number}`
                                  : ""}
                              </span>

                              
                              <Button
                                className="btn btn-default sx-logoutBtn"
                                onClick={handleLogout}
                              >
                                {translation.logOut}
                              </Button>
                            </div>
                            <div className="d-flex align-items-center mt-2 mb-4 px-3">
                              <div className="sx-loginAccountName mr-3">
                                {`${user.FirstName.charAt(
                                  0
                                )}${user.LastName.charAt(0)}`}
                              </div>
                              <div className="d-flex flex-column">
                                <span className="sx-lh-initial font-weight-bold">
                                  {`${user.FirstName} ${user.LastName}`}
                                </span>
                                <span className="small sx-lh-initial">
                                  {user.Email}
                                </span>
                                <Link
                                  className="small mt-1 sx-underLine"
                                  to="/changepassword"
                                >
                                  {translation.changePassword}
                                </Link>
                              </div>
                            </div>
                            {user.IsLoginForMultipleAccounts &&
                              user.Accounts.length > 1 && (
                                <Link
                                  className="sx-switchAccount small tw-flex tw-items-center tw-justify-center"
                                  to="#"
                                  onClick={() => setOpenSelectAccount(true)}
                                >
                                  <img
                                    src={require("../../images/changes.png")}
                                    alt="Solmetex"
                                    className="img-fluid mr-2 sx-chnageIcon"
                                  />
                                  {translation.changeAccount}
                                </Link>
                              )}
                          </div>
                        )}
                      </Box>
                      <Box className="sx-hideOnDesktop d-flex align-items-center ml-3">
                          <IconButton aria-label="menu" onClick={handleToggle}>
                            {isMobile ? <Close /> : <Menu />}
                          </IconButton>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Navbar>
              {(user.IsDsoLogin == false || showSelectedAccountData) && (
                <Box className="sx-navigationDesktopOnly sx-navigation">
                  <ul className="nav justify-content-center">
                    {Tabs.map((tab) => {
                      return (
                        <li className="nav-item">
                          <Link
                            className={`align-items-center d-flex justify-content-center flex-column text-uppercase w-100 nav-link ${
                              selectedTab == tab.id ? "active" : ""
                            }`}
                            to={tab.url}
                            onClick={() => setSelectedTab(tab.id)}
                          >
                            <img
                              src={tab.icon}
                              alt={tab.name}
                              className="img-fluid mb-2"
                            />
                            {tab.name}
                            <span className="h-26">
                              {tab.subText ? (
                                <span className="sx-navSubText">
                                  {tab.subText}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              )}
              {isMobile && (
                <Box className="sx-navigationMobileOnly sx-navigation ">
                  <Box>
                    {user.IsDsoLogin && (
                      <ul className="nav justify-content-center flex-column">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#"
                            onClick={() => {
                              handleNavigation("/dashboard");
                              setIsMobile(false);
                            }}
                          >
                            {translation.dashboard}
                          </Link>
                        </li>
                      </ul>
                    )}
                    {(user.IsDsoLogin == false || showSelectedAccountData) && (
                      <ul className="nav justify-content-center flex-column">
                        {Tabs.map((tab) => {
                          return (
                            <li className="nav-item">
                              <Link
                                className={`d-flex flex-column text-uppercase nav-link ${
                                  selectedTab == tab.id ? "active" : ""
                                }`}
                                to={tab.url}
                                onClick={() => {
                                  setSelectedTab(tab.id);
                                  setIsMobile(false);
                                }}
                              >
                                <span className="align-items-center d-flex flex-row">
                                  <img
                                    src={tab.icon}
                                    alt={tab.name}
                                    className="img-fluid mr-3"
                                  />
                                  {tab.name}
                                </span>
                                <span className="mx-auto h-26">
                                  {tab.subText ? (
                                    <span className="sx-navSubText mt-2">
                                      {tab.subText}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Box>
                  {/* <Box>
                    <div
                      ref={wrapperRef}
                      className="sx-myAccountMobileOnly flex-column d-flex my-2"
                    >
                      <div className="d-flex align-items-center px-3">
                        <div className="sx-loginAccountName mr-3">
                          {`${user.FirstName.charAt(0)}${user.LastName.charAt(
                            0
                          )}`}
                        </div>
                        <div className="d-flex flex-column">
                          <span className="sx-lh-initial font-weight-bold">
                            {`${user.FirstName} ${user.LastName}`}
                          </span>
                          <span className="small sx-lh-initial">
                            {user.Email}
                          </span>
                          <span className="small small">
                            {selectedAccount.ID != ""
                              ? `${translation.accountNumber} : ${selectedAccount.Number}`
                              : ""}
                          </span>
                          <Link
                            className="small mt-1 sx-underLine"
                            to="/changepassword"
                          >
                            {translation.changePassword}
                          </Link>
                        </div>
                      </div>
                      {user.IsLoginForMultipleAccounts &&
                        user.Accounts.length > 1 && (
                          <Link
                            className="sx-switchAccount small"
                            to="#"
                            onClick={() => setOpenSelectAccount(true)}
                          >
                            <img
                              src={require("../../images/changes.png")}
                              alt="Solmetex"
                              className="img-fluid mr-2 sx-chnageIcon"
                            />
                            {translation.changeAccount}
                          </Link>
                        )}
                    </div>
                    <ul className="nav justify-content-center flex-column">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#"
                          onClick={handleLogout}
                        >
                          {translation.logOut}
                        </Link>
                      </li>
                    </ul>
                  </Box> */}
                </Box>
              )}
            </header>
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default connector(NavMenu);
