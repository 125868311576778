import {
    styled,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import { getDate } from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";

const translation = UseTranslation();

export type OrderType = "asc" | "desc";

export interface HeadCell {
    disableSorting: boolean;
    id: string;
    label: string;
    numeric: boolean;
    sticky?: boolean;       // Optional property
    stickyLeft?: string;
    stickyWidth?:string
}

export interface EnhancedTableProps {
    order: OrderType;
    orderBy: string;
    headCells: HeadCell[];
    rowCount: number;
    displayActionCell: boolean;
    activeColumns?: string[];
    onRequestSort: (property: string) => void;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function descendingComparatorByDate(a: any, b: any, orderBy: any) {
    if (getDate(a.CreatedOn) < getDate(b.CreatedOn)) {
        return -1;
    }
    if (getDate(b.CreatedOn) > getDate(a.CreatedOn)) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: OrderType,
    orderBy: Key
): (a: any, b: any) => number {
    if (orderBy == "CreatedOn") {
        return order === "desc"
            ? (a, b) => descendingComparatorByDate(a, b, orderBy)
            : (a, b) => -descendingComparatorByDate(a, b, orderBy);
    } else {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
        order,
        orderBy,
        headCells,
        displayActionCell,
        activeColumns,
        onRequestSort,
    } = props;

    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(property);
        };

    return (
        <TableHead className="sx-tableHeader">
            <TableRow>
                {headCells.map((headCell) => {
                    if (
                        activeColumns == null ||
                        (activeColumns && activeColumns.includes(headCell.id))
                    ) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={
                                    headCell.sticky
                                        ? { position: 'sticky', left: headCell.stickyLeft || 0, zIndex: 9, backgroundColor: '#ccebfa', width: headCell.stickyWidth || 0 } // Use `||` for compatibility with undefined values
                                        : undefined // Use `undefined` for no inline styles
                                }
                            >
                                {headCell.disableSorting ? (
                                    <Typography>{headCell.label}</Typography>
                                ) : (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        <Typography>{headCell.label}</Typography>
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        );
                    }
                })}
                {displayActionCell && <TableCell>{translation.action}</TableCell>}
            </TableRow>
        </TableHead>
    );
};
