import jsPDF from "jspdf";
import moment from "moment";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { Account, ComplianceStatus, mimeDict } from "../types/Master";
import UseTranslation from "./UseTranslation";
import homeIcon from "../images/HomeIcon.svg";
import recyclingCertificateIcon from "../images/RcIcon.svg";
import productRegistrationIcon from "../images/PrIcon.svg";
import subscriptionServicesIcon from "../images/SsIcon.svg";
import waterTestingIcon from "../images/WtIcon.svg";
import supportCaseIcon from "../images/ScIcon.svg";

const translation = UseTranslation();

export const TabTypes = {
  HOME: 0,
  RECYCLED_CERTIFICATES: 1,
  PRODUCT_REGISTRATION: 2,
  MAINTENANCE_PLANS: 3,
  SHIPPING_LABEL: 4,
  PRODUCT_ISSUE: 5,
  PROMOTION: 6,
  WATERTESTING: 7,
};

export const Tabs = [
  {
    id: TabTypes.HOME,
    name: "Home",
    url: "/home",
    icon: homeIcon,
    subText: "",
  },
  {
    id: TabTypes.RECYCLED_CERTIFICATES,
    name: "Recycling Certificates",
    url: "/recyclingcertificate",
    icon: recyclingCertificateIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.PRODUCT_REGISTRATION,
    name: "Product Registration",
    url: "/productregistration",
    icon: productRegistrationIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.MAINTENANCE_PLANS,
    name: "Subscription Services",
    url: "/maintenanceplans",
    icon: subscriptionServicesIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.WATERTESTING,
    name: "Water Testing",
    url: "/watertesting",
    icon: waterTestingIcon,
    subText: "",
  },
  {
    id: TabTypes.PRODUCT_ISSUE,
    name: "Support Cases",
    url: "/cases",
    icon: supportCaseIcon,
    subText: "",
  },
];

export const getDate = (val: string): Date => {
  return new Date(val);
};

export const getFormatedDate = (val: string): string => {
  return moment(val).format("YYYY-MM-DD");
};

export const getFormatedDateForDisplay = (val: string): string => {
  return moment(val).format("MM-DD-YYYY");
};

export const getLongDate = (val: string): string => {
  return moment(val).format("MMMM DD, YYYY");
};

export const getNextShipDate = (): string => {
  let currentday = moment().get("date");
  let nextDate: moment.Moment;
  let now: moment.Moment;
  if (currentday <= 10) {
    now = moment().add("1", "months");
    nextDate = moment(`${now.get("year")}-${now.get("month") + 1}-01`);
  } else {
    now = moment().add("2", "months");
    nextDate = moment(`${now.get("year")}-${now.get("month") + 1}-01`);
  }
  return nextDate.format("YYYY-MM-DD");
};

export const getfileType = (extension: string): string => {
  switch (extension) {
    case "pdf":
      return mimeDict.pdf;
      break;
    case "png":
      return mimeDict.png;
      break;
    case "jpeg":
      return mimeDict.jpeg;
      break;
    case "gif":
      return mimeDict.gif;
      break;
    default:
      return "";
  }
};

export const generatePDF = (
  tableRows: any[],
  tableColumn: string[],
  fileName: string,
  columnsizes?: any
) => {
  const doc = new jsPDF();

  autoTable(doc, {
    head: [tableColumn],
    body: tableRows,
    margin: 0,
    columnStyles: columnsizes,
  });

  const dateStr = moment(new Date()).format("MM-DD-YYYY");
  doc.save(`${fileName}_${dateStr}.pdf`);
};

export const generateExcel = (
  tableRows: any[],
  tableColumn: string[],
  fileName: string
) => {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [tableColumn]);
  XLSX.utils.sheet_add_json(ws, tableRows, {
    origin: "A2",
    skipHeader: true,
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const dateStr = moment(new Date()).format("MM-DD-YYYY");
  XLSX.writeFile(wb, `${fileName}_${dateStr}.xlsx`);
};

export const getComplianceStatus = (val: ComplianceStatus): string => {
  switch (val) {
    case ComplianceStatus.InCompliance:
      return translation.incompliance;
    case ComplianceStatus.ApproachingDeadline:
      return translation.aproachingDeadline;
    case ComplianceStatus.OutOfCompliance:
      return translation.outOfCompliance;
    case ComplianceStatus.NoProductReported:
      return translation.noProductReported;
    default:
      return "";
  }
};

export const getStatusValue = (complianceStatusType: string, item: Account) => {
  switch (complianceStatusType) {
    case translation.amalgamContainers:
      return item.AmalgamContainerStatus;
    case translation.amalgamBuckets:
      return item.AmalgamBucketsStatus;
    case translation.straws:
      return item.StrawsStatus;
    case translation.waterTesting:
      return item.WaterTestingStatus;
    default:
      return ComplianceStatus.None;
  }
};

export const CaseTypes = [
  {
    id: 1,
    name: "Product Support",
  },
  {
    id: 7,
    name: "Order Inquiry",
  },
];

export const getCaseStatus = (val: string): string => {
  switch (val) {
    case "0":
      return "Active";
    case "1":
      return "Resolved";
    case "2":
      return "Cancelled";
    default:
      return "";
  }
};

export const WaterTestDSBrands = [
  {
    id: "100000000",
    name: "BluTab",
    type: "100000000",
  },
  {
    id: "100000001",
    name: "ICX",
    type: "100000000",
  },
  {
    id: "100000002",
    name: "Citrisil",
    type: "100000000",
  },
  {
    id: "100000003",
    name: "Citrisil Blue",
    type: "100000000",
  },
  {
    id: "100000004",
    name: "Dentapure Straw",
    type: "100000001",
  },
  {
    id: "100000005",
    name: "Sterisil Straw",
    type: "100000001",
  },
  {
    id: "100000006",
    name: "ProEdge BluTube Straw",
    type: "100000001",
  },
  {
    id: "100000007",
    name: "Other",
    type: "100000001",
  },
  {
    id: "100000007",
    name: "Other",
    type: "100000000",
  },
];

export const WaterTestDSTypes = [
  {
    id: "100000000",
    name: "Tablet",
  },
  {
    id: "100000001",
    name: "Straw",
  },
  {
    id: "100000002",
    name: "Other Water Treatment",
  },
  {
    id: "100000003",
    name: "We Don't Have Water Bottles",
  },
];

export const ShockProductUsed = [
  {
    id: "100000000",
    name: "Sterilex",
  },
  {
    id: "100000001",
    name: "Mint-A-Kleen",
  },
  {
    id: "100000002",
    name: "Citrisil Shock",
  },
  {
    id: "100000003",
    name: "Bleach",
  },
  {
    id: "100000004",
    name: "Other",
  },
];

export const ShockBeforeInstall = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "0",
    name: "No",
  },
];

export const DeviceorSourceWater = [
  {
    id: "100000000",
    name: "A/W Syringe",
  },
  {
    id: "100000001",
    name: "Handpiece",
  },
  {
    id: "100000002",
    name: "Cavitron",
  },
  {
    id: "100000003",
    name: "Source Water",
  },
  {
    id: "100000005",
    name: "MultiSource",
  },
  {
    id: "100000004",
    name: "Other",
  },
];



export const MultiSourceOptions = [
  {
    id: "100000000",
    name: "A/W Syringe",
  },
  {
    id: "100000001",
    name: "Handpiece",
  },
  {
    id: "100000002",
    name: "Cavitron",
  },
  {
    id: "100000003",
    name: "Source Water",
  },
  {
    id: "100000004",
    name: "Other",
  },
];


export const DeviceorSourceWaterHeader = [
  {
    id: "1",
    name: "A/W Syringe",
  },
  {
    id: "2",
    name: "Handpiece",
  },
  {
    id: "3",
    name: "Cavitron",
  },
  {
    id: "4",
    name: "Source Water",
  },  
  {
    id: "5",
    name: "Other",
  },
];
export const getDeviceorSourceWater = (val: string[]): string => {
  if (val == null || val.length === 0) {
    return "";
  }

  const deviceorSourceWaterList = val.map((item) => {
    switch (item) {
      case "1":
        return "A/W Syringe";
      case "2":
        return "Handpiece";
      case "3":
        return "Cavitron";
      case "4":
        return "Source Water";
      case "5":
        return "Other";      
      default:
        return "";
    }
  });

  // Filter out any empty strings in case of unmatched values and join with commas
  return deviceorSourceWaterList.filter(Boolean).join(", ");
};

export const getSampleResult = (val: string): string => {
  if (val != null && val.length > 0) {
    switch (val.toString()) {
      case "0":
        return "Fail";
      case "1":
        return "Pass";
      default:
        return "";
    }
  } else {
    return "";    
  }
};

export const SourceWaterDS = [
  {
    id: "100000000",
    name: "City Water Faucet",
  },
  {
    id: "100000001",
    name: "Bottled Distilled",
  },
  {
    id: "100000002",
    name: "Filter, Distiller, or RO Unity",
  },
  {
    id: "100000003",
    name: "Other",
  },
];

export const BottleOrMunicipal = [
  {
    id: "100000000",
    name: "Bottle",
  },
  {
    id: "100000001",
    name: "Municipal",
  },
];

export const WaterTestTypeValues = {
  MAIL_IN: "186680001",
  IN_OFFICE: "186680000",
};

export const WaterTestTypes = [
  {
    id: WaterTestTypeValues.IN_OFFICE,
    name: "In Office",
  },
  {
    id: WaterTestTypeValues.MAIL_IN,
    name: "Mail In",
  },
];

export const InOfficeTestTypes = [
  {
    id:"1",
    name: "FASTCheck15",
  },
  {
    id: "2",
    name: "Paddle Test",
  },
  {
    id: "3",
    name: "Other",
  },
];

export const MailInTestTypes = [
  {
    id:"1",
    name: "R2A Lab Test",
  },
  {
    id: "2",
    name: "Flow Cytometry/24-Hr Test",
  },
  {
    id: "3",
    name: "Other",
  },
];

export const getWaterTestTypes = (val: string): string => {
  switch (val.toString()) {
    case WaterTestTypeValues.IN_OFFICE:
      return "In Office";
    case WaterTestTypeValues.MAIL_IN:
      return "Mail In";
    default:
      return "";
  }
};

export const getWaterTestPassFail = (val: string): string => {
  switch (val.toString()) {
    case "1":
      return "Fail";
    case "0":
      return "Pass";
    default:
      return "";
  }
};

export const getWaterTypes = (val: string): string => {
  switch (val.toString()) {
    case  "1":
      return "Distilled";
    case "2":
      return "Municipal";
    default:
      return "";
  }
};

export const getWaterTestingResults = (val: string): string => {
  switch (val.toString()) {
    case "186680000":
      return "Pass";
    case "186680001":
      return "Fail";
    default:
      return "";
  }
};

export const WaterTestingResults = [
  {
    id: "186680000",
    name: "Pass",
  },
  {
    id: "186680001",
    name: "Fail",
  },
];

export const WaterTypes = [
  {
    id: "1",
    name: "Distilled",
  },
  {
    id: "2",
    name: "Municipal",
  },
];


export const getPromotionCaseStatus = (val: string): string => {
  switch (val) {
    case "186680002":
      return "Approved";
    case "186680000":
      return "New";
    case "186680003":
      return "Not Approved";
    case "186680001":
      return "Under Review";
    default:
      return "";
  }
};

export const YesOrNoOptions = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "0",
    name: "No",
  },
];

export const SampleResults = [
  {
    id: "0",
    name: "Pass",
  },
  {
    id: "1",
    name: "Fail",
  },
];



export const WaterTestRecordTypeValues = {
  STANDARD: "1",
  RETEST: "2",
};

export const WaterTestingRecordType = [
  {
    id: WaterTestRecordTypeValues.STANDARD,
    name: "Standard",
  },
  {
    id: WaterTestRecordTypeValues.RETEST,
    name: "Retest",
  },
];

export const getWaterTestRecotdType = (val: string): string => {
  switch (val.toString()) {
    case WaterTestRecordTypeValues.STANDARD:
      return "Standard";
    case WaterTestRecordTypeValues.RETEST:
      return "Retest";
    default:
      return "";
  }
};
export const DentisafeIdsToRedirect = [
    "656", "744", "745", "746", "747", "748", "749", "750", "751", "752", "753", "754", "755", "756",
    "757", "758", "759", "760", "761", "762", "763", "764", "765", "766", "767", "768", "769", "770", "771", "772",
    "773", "774", "775", "776", "777", "778", "779", "780", "781", "782", "783", "784", "785", "786", "787", "788",
    "789", "790", "791", "792", "793", "794", "795", "796", "797", "798", "799", "800", "801", "802", "803", "804",
    "805", "806", "807", "808", "809", "810", "811", "812", "813", "814", "815", "816", "817", "818", "819", "820",
    "821", "822", "823", "824", "825", "826", "827", "828", "829", "830", "831", "832", "833", "834", "835", "836",
    "837", "838", "839", "840", "841", "842", "843", "844", "845", "846", "847", "848", "849", "850", "851", "852",
    "853", "854", "855", "856", "857", "858", "859", "860", "861", "862", "863", "864", "865", "866", "867", "868",
    "869", "870", "871", "872", "873", "874", "875", "876", "877", "878", "879", "880", "881", "882", "883", "884",
    "885", "886", "887", "888", "889", "890", "891", "892", "893", "915", "916", "917", "918", "919", "920", "921",
    "922", "923", "924", "925", "926", "927", "928", "929", "930", "931", "932", "933", "934", "935", "936", "937",
    "938", "939", "940", "941", "942", "943", "944", "945", "946", "947", "948", "949", "950", "951", "952", "953",
    "954", "955", "956", "957", "958", "959", "960", "961", "962", "963", "964", "965", "966", "967", "968", "969",
    "970", "971", "972", "973", "974", "975", "976", "977", "978", "979", "980", "981", "982", "983", "984", "985",
    "986", "987", "988", "989", "990", "991", "992", "993", "994", "995", "996", "997", "998", "999", "1000", "1001",
    "1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009", "1010", "1011", "1012", "1013", "1014", "1015",
    "1016", "1017", "1018", "1019", "1020", "1021", "1022", "1023", "1024", "1025", "1026", "1027", "1028", "1029",
    "1030", "1031", "1032", "1033", "1034", "1035", "1036", "1037", "1038", "1039", "1040", "1041", "1042", "1043",
    "1044", "1045", "1046", "1047", "1048", "1049", "1050", "1051", "1052", "1053", "1054", "1055", "1056", "1057",
    "1058", "1059", "1060", "1061", "1062", "1063", "1064", "1065", "1066", "1067", "1068", "1069", "1070", "1071",
    "1072", "1073", "1074", "1075", "1076", "1077", "1078", "1079", "1080", "1081", "1082", "1083", "1084", "1085",
    "1086", "1087", "1088", "1089", "1090", "1091", "1092", "1093", "1094", "1095", "1096", "1097", "1098", "1099",
    "1100", "1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109", "1110", "1111", "1112", "1113",
    "1114", "1115", "1116", "1117", "1118", "1119", "1120", "1121", "1122", "1123", "1124", "1125", "1126", "1127",
    "1128", "1129", "1130", "1131", "1132", "1133", "1134", "1135", "1136", "1137", "1138", "1139", "1140", "1141",
    "1142", "1143", "1144", "1145", "1146", "1147", "1148", "1149", "1150", "1151", "1152", "1153", "1154", "1155",
    "1156", "1157", "1158", "1159", "1160", "1161", "1162", "1163", "1164", "1165", "1166", "1167", "1168", "1169",
    "1170", "1171", "1172", "1173", "1174", "1175", "1176", "1177", "1178", "1179", "1180", "1181", "1182", "1183",
    "1184", "1185", "1186", "1187", "1188", "1189", "1190", "1191", "1192", "1193", "1194", "1195", "1196", "1197",
    "1198", "1199", "1200", "1201", "1202", "1203", "1204", "1205", "1206", "1207", "1208", "1209", "1210", "1211",
    "1212", "1213", "1214"
];