import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,  
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import * as React from "react";
import { Close, Add } from "@material-ui/icons";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import {
  WaterTestTypeValues,
  WaterTestingRecordType,
  YesOrNoOptions,
  getfileType, 
  SampleResults,
  WaterTypes,
  DeviceorSourceWaterHeader,
  InOfficeTestTypes,
  MailInTestTypes,
} from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  User,
  defaultAccount,
  WaterTesting,
  defaultWaterTesting,
  Vial,
} from "../../types/Master";
import {  
  OrderType,
} from "../shared/Table";
import {
  WaterTestTypes  
} from "../../services/Shared";
import {
  CreateWaterTesting 
} from "../../services/ProductService";

const translation = UseTranslation();

interface errorValidation {
  testType: string;
  watertype: string;
  recordType: string;
  dateofSample: string;
  passFail: string;
  deviceorSourceWater:string;
  operatory: string;
  sampleFailed: string;
  waterType: string;
}

const defaultErrorValidation = {
  testType: "",
  watertype: "",
  recordType: "",
  dateofSample: "",
  testerName: "",
  operatory: "",
  sampleFailed: "",
  waterType: "",
  passFail:"",
  deviceorSourceWater:""
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface OwnProps {
  updateWaterTesting: WaterTesting;
  getwaterTestingData: () => Promise<void>;
  readOnlyMode: boolean;
  setReadOnlyMode: (mode: boolean) => void;
  reTest: boolean;
}

export type ddWaterTestingProps = PropsFromRedux & OwnProps;

const AddWaterTesting = (props: ddWaterTestingProps) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    updateWaterTesting,
    getwaterTestingData,
    readOnlyMode,
    setReadOnlyMode,
    reTest,
    selectedAccount,
    selectedAccountContact,
    user,
  } = props;
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [waterTestingDataRequest, setWaterTestingDataRequest] =
    React.useState<WaterTesting>(defaultWaterTesting);
  const [files, setFiles] = React.useState<FileList | null>();
  const [displayTestingLab, setDisplayTestingLab] = React.useState(false);
  const [fileSizeError, setFileSizeError] = React.useState("");
  const [isOneOrMoreSamplesFail, setIsOneOrMoreSamplesFail] =
    React.useState(false);
  const [isTestResultsUploaded, setIsTestResultsUploaded] =
    React.useState(false);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  const [showOperatoryRequired, setShowOperatoryRequired] =
    React.useState<boolean>(false);
  const [vials, setVials] = React.useState<Vial[]>([]);
  const [showTesterNameRequired, setTesterNameRequired] =
    React.useState<boolean>(false);
  const [showWaterTypeRequired, setWaterTypRequired] =
    React.useState<boolean>(false);
  const [showDeviceRequired, setShowDeviceRequired] =
    React.useState<boolean>(false);
  const [showMailInTypes, setShowMailInTypes] = React.useState<boolean>(false);
  const [showInOfficeTypes, setShowInOfficeTypes] =
    React.useState<boolean>(false);
  React.useState<boolean>(false); 
  const [readOnly, setReadOnly] = React.useState(readOnlyMode);
  const [restTest, setReTest] = React.useState(reTest);
  const [showsampleDateRequired, setShowSampleDateRequired] =
    React.useState<boolean>(false);
    const[showSampleRequired,setShowSampleRequired]= React.useState<boolean>(false);
  const [dialogTitle, setDialogTitle] =
    React.useState<string>("New Water Testing");
  const [mailInOrInofficeType, setMailInOrInofficeType] = React.useState("");
  const [invalidDateMessage,setInvalidDateMessage]= React.useState("Required");

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    if (files && files.length > 0) {
      let file = files[0];
      if (file.size < 10485760) {
        setFiles(files);
        setFileSizeError("");
      } else setFileSizeError(translation.fileSizeError);
      setIsTestResultsUploaded(true);
    } else {
      setIsTestResultsUploaded(false);
    }
  };

  React.useEffect(() => {
    if (updateWaterTesting.TestType == WaterTestTypeValues.MAIL_IN) {
      setShowMailInTypes(true);
      setShowInOfficeTypes(false);
    } else if (updateWaterTesting.TestType == WaterTestTypeValues.IN_OFFICE) {
      setShowMailInTypes(false);
      setShowInOfficeTypes(true);
    }
    if (updateWaterTesting.SampleFailed) {
      setIsOneOrMoreSamplesFail(true);
    } else {
      setIsOneOrMoreSamplesFail(false);
    }

    if (reTest) {
      setDialogTitle("Retest Water Testing Details");
      setIsAdNew(true);
      setReTest(true);
      setIsOneOrMoreSamplesFail(false);
      let now: moment.Moment;
      now = moment();
      setWaterTestingDataRequest({
        ...updateWaterTesting,
        ParentID: updateWaterTesting.ID,
        DateofSample: now.format("YYYY-MM-DD"),
        RecordType: WaterTestingRecordType[1].id,
        AdditionalNotes: "",
        TesterName: "",
        TestType: "",
        InOfficeTestTypes: "",
        MailInTestTypes: "",
        DeviceOrSourceWater: [],
        SampleFailed: "",
        RequestOrder: "",
        RequestContact: "",
        ID: "",
      });
    } else if (updateWaterTesting.ID != "") {
      if (!readOnlyMode) {
        setDialogTitle("Update Water Testing");
      } else {
        setDialogTitle("View Water Testing Details");
      }
      setIsAdNew(true);
      setWaterTestingDataRequest({
        ...updateWaterTesting,
        DateofSample: updateWaterTesting.DateofSample.substring(0, 10),
        SampleFailed: updateWaterTesting.SampleFailed,
        Vials: vials,
      });
    } else {
      setVials([]);
    }
  }, [updateWaterTesting]);

  const handleAddNew = (e: any) => {
    e.preventDefault();
    let now: moment.Moment;
    now = moment();
    setWaterTestingDataRequest({
      ...defaultWaterTesting,
      Customer: { ...defaultAccount, ID: selectedAccount.ID },
      DateofSample: now.format("YYYY-MM-DD"),
      RecordType: WaterTestingRecordType[0].id,
      AdditionalNotes: "",
      SampleFailed: "",
      RequestContact: "",
      RequestOrder: "",
    });
    setFiles(null);
    setFileSizeError("");
    setIsAdNew(true);
    setReadOnly(false);
    setReadOnlyMode(false);
    setDialogTitle("New Water Testing Details");

    setReTest(false);
    let errorsObject = { ...defaultErrorValidation };
    setErrors({ ...errorsObject });
  };

  const handleClose = () => {
    setIsAdNew(false);
    if (reTest) {
      setReTest(false);
    }
    setShowOperatoryRequired(false);
    setWaterTypRequired(false);
    setTesterNameRequired(false);
    setShowSampleDateRequired(false);
  };

  const errorValidation = (request: WaterTesting): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.OperatoryNumber == "") {
      errorsObject = {
        ...errorsObject,
        operatory: translation.requiredField,
      };
      setShowOperatoryRequired(true);
      isValid = false;
    } else {
      setShowOperatoryRequired(false);
    }

    if (request.TestType == "") {
      errorsObject = { ...errorsObject, testType: translation.requiredField };
      isValid = false;
    }

    if (request.DeviceOrSourceWater==null || request.DeviceOrSourceWater.length==0) {
      errorsObject = { ...errorsObject, deviceorSourceWater: translation.requiredField };
      isValid = false;
    }
    if (request.SampleFailed=="") {
      setShowSampleRequired(true);
      errorsObject = { ...errorsObject, passFail: "Required"};
      isValid = false;
    }
    else{
      setShowSampleRequired(false);
    }

    if (request.RecordType == "") {
      errorsObject = { ...errorsObject, recordType: translation.requiredField };
      isValid = false;
    }
    if (request.DateofSample == "") {
      setShowSampleDateRequired(true);
      errorsObject = {
        ...errorsObject,
        dateofSample: translation.requiredField,
      };
      isValid = false;
    } else if (reTest && request.DateofSample != "") {
      const requestDate = new Date(request.DateofSample);
      const updateWaterTestingDate = new Date(updateWaterTesting.DateofSample);

      if (requestDate <= updateWaterTestingDate) {
        // Handle invalid dates if necessary
        setShowSampleDateRequired(true);
        setInvalidDateMessage("Date should be greater than the previous test date")
        errorsObject = {
          ...errorsObject,
          dateofSample: "Invalid date",
        };
        isValid = false;
      }
    } else {
      setShowSampleDateRequired(false);
      setInvalidDateMessage("Invalid date")
    }
    if (request.WaterType == "") {
      errorsObject = {
        ...errorsObject,
        waterType: translation.requiredField,
      };
      setWaterTypRequired(true);
      isValid = false;
    } else {
      setWaterTypRequired(false);
    }
    

    setErrors({ ...errorsObject });
    return isValid;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var ContactID = user.ID;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      ContactID = selectedAccountContact.ID;
    }

    if (errorValidation(waterTestingDataRequest)) {
      const data = new FormData(event.currentTarget);
      setLoader(true);
      data.append("ID", waterTestingDataRequest.ID);
      data.append("CustomerId", selectedAccount.ID);
      data.append("CustomerNumber", selectedAccount.Number);
      data.append("SubmittedBy", ContactID);
      data.append("ParentID", waterTestingDataRequest.ParentID);
      if (waterTestingDataRequest.ParentID != "") {
        data.append("ID", "");
      }
      data.append("OperatoryNumber", waterTestingDataRequest.OperatoryNumber);
      data.append("WaterType", waterTestingDataRequest.WaterType);
      data.append("SampleFailed", waterTestingDataRequest.SampleFailed);

      data.append("RequestContact", waterTestingDataRequest.RequestContact);
      data.append(
        "CertificateRequest",
        waterTestingDataRequest.CertificateRequest ? "1" : "0"
      );
      var dateofSample = waterTestingDataRequest.DateofSample;
      if (waterTestingDataRequest.DateofSample.length > 10) {
        dateofSample = waterTestingDataRequest.DateofSample.substring(0, 10);
      }
      data.append("AdditionalNotes", waterTestingDataRequest.AdditionalNotes);
      if (
        waterTestingDataRequest.DeviceOrSourceWater != null &&
        waterTestingDataRequest.DeviceOrSourceWater.length > 0
      ) {
        const commaSeparatedString =
          waterTestingDataRequest.DeviceOrSourceWater.join(", ");
        data.append("strDeviceSourceWater", commaSeparatedString);
      }

      if (files && files.length > 0) {
        data.append("ImageUpdated", "true");
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension ? getfileType(extension) : "";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("fileType", fileType);
      }

      CreateWaterTesting(data)
        .then((res) => {
          setIsAdNew(false);
          getwaterTestingData()
            .then(() => {
              setLoader(false);
              if (waterTestingDataRequest.ID != "")
                setToastMessage(translation.waterTestingUpdateSuccess, true);
              else setToastMessage(translation.waterTestingSuccess, true);
              setWaterTestingDataRequest({ ...defaultWaterTesting });
              setFiles(null);
            })
            .catch((ex) => {
              console.error(ex);
            });
        })
        .catch((ex) => {
          setWaterTestingDataRequest({ ...defaultWaterTesting });
          setFiles(null);
          setLoader(false);
          setIsAdNew(false);
          setToastMessage(translation.watertTestingFailure, false);
          console.error(ex);
        });
    }
  };

  const handleTestTypeChange = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      TestType: e.target.value,
    });
    if (e.target.value == "186680001") {
      setShowMailInTypes(true);
      setMailInOrInofficeType("Mail In Types");
      setShowInOfficeTypes(false);
    } else {
      setShowMailInTypes(false);
      setMailInOrInofficeType("In Office Types");
      setShowInOfficeTypes(true);
    }
    setDisplayTestingLab(false);
  };

  const handleMailInTestTypeChange = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      MailInTestTypes: e.target.value,
    });
    if (e.target.value == "3") {
      setDisplayTestingLab(true);
    } else {
      setDisplayTestingLab(false);
    }
  };

  const handleInOfficeTestTypeChange = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      InOfficeTestTypes: e.target.value,
    });
    if (e.target.value == "3") {
      setDisplayTestingLab(true);
    } else {
      setDisplayTestingLab(false);
    }
  };

  const handleWaterTypeChange = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      WaterType: e.target.value,
    });
    if (e.target.value == null || e.target.value == "") {
      setWaterTypRequired(true);
    } else {
      setWaterTypRequired(false);
    }
  };

  const handleDeviceorSourceWater = (e: any, id: any) => {
    const currentDeviceOrSourceWater =
      waterTestingDataRequest.DeviceOrSourceWater || [];
    const newDeviceOrSourceWater = e.target.checked
      ? [...currentDeviceOrSourceWater, id]
      : currentDeviceOrSourceWater.filter((item) => item !== id);

    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      DeviceOrSourceWater: newDeviceOrSourceWater,
    });
    if (newDeviceOrSourceWater.length > 0) {
      setShowDeviceRequired(false);
    } else {
      setShowDeviceRequired(true);
    }
  };

  const handleSampleFailedStatus = (e: any) => {
    if (e.target.value == "1") {
      setWaterTestingDataRequest({
        ...waterTestingDataRequest,
        SampleFailed: "1",
      });
      setIsOneOrMoreSamplesFail(true);
    } else {
      setWaterTestingDataRequest({
        ...waterTestingDataRequest,
        SampleFailed: "0",
      });
      setIsOneOrMoreSamplesFail(false);
    }
  };

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog
        className="mb-4 sx-addRegistrationDialog px-3"
        open={isAddNew}
        onClose={handleClose}
        maxWidth="lg"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle className="sx-model-title sx-border-bottom">
            {dialogTitle}
          </DialogTitle>

          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent className="mb-4 px-3">
            <TextField
              className={`sx-inputGroup ${
                showOperatoryRequired && "sx-validationFailed"
              }`}
              margin="normal"
              fullWidth
              id="Operatory"
              name="OperatoryNumber"
              type="text"
              label="Operatory Name/Number *"
              onChange={(e) => {
                setWaterTestingDataRequest({
                  ...waterTestingDataRequest,
                  OperatoryNumber: e.target.value as string,
                });
              }}
              value={waterTestingDataRequest.OperatoryNumber}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
              disabled={dialogTitle === "Retest Water Testing Details"}
            />
            {showOperatoryRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
            <Box className="sx-input" sx={{ width: "100%" }}>
              <TextField
                className={`sx-inputGroup my-2 ${
                  showsampleDateRequired && "sx-validationFailed"
                }`}
                fullWidth
                id="DateofSample"
                name="DateofSample"
                label={translation.dateofsample}
                type="date"
                value={
                  waterTestingDataRequest.DateofSample.substring(0, 10) ||
                  moment().format("YYYY-MM-DD")
                }
                onChange={(e) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    DateofSample: e.target.value,
                  });
                }}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                disabled={readOnlyMode}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />

              {showsampleDateRequired && (
                <div className="error-text font-10 pl-4 text-left">
                  {invalidDateMessage}
                </div>
              )}
            </Box>

            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-type"
                name="WaterType"
                className={`sx-input ${
                  errors && errors.waterType && "sx-validationFailed"
                }`}
                disableUnderline={true}
                disabled={dialogTitle === "Retest Water Testing Details"}
                value={waterTestingDataRequest.WaterType}
                onChange={handleWaterTypeChange}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.WaterType}
                </MenuItem>
                {WaterTypes.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.waterType}
              </FormHelperText>
            </FormControl>
            {showWaterTypeRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
            <FormControl fullWidth variant="filled">
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="testername"
                name="testerName"
                type="text"
                label={translation.testerName}
                onChange={(e) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    TesterName: e.target.value as string,
                  });
                }}
                value={waterTestingDataRequest.TesterName}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 1 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />             
            </FormControl>

            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-type"
                name="TestType"
                className={`sx-input ${
                  errors && errors.testType && "sx-validationFailed"
                }`}
                disableUnderline={true}
                disabled={readOnlyMode}
                value={waterTestingDataRequest.TestType}
                onChange={handleTestTypeChange}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectTypeOfTest}
                </MenuItem>
                {WaterTestTypes.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.testType}
              </FormHelperText>
            </FormControl>

            {showMailInTypes && (
              <FormControl
                fullWidth
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Select
                  id="mail-type"
                  name="MailInTestTypes"
                  className={`sx-input ${
                    errors && errors.testType && "sx-validationFailed"
                  }`}
                  disableUnderline={true}
                  value={waterTestingDataRequest.MailInTestTypes}
                  onChange={handleMailInTestTypeChange}
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    Select Mail In Type *
                  </MenuItem>
                  {MailInTestTypes.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText error className="error-text font-10 text-left">
                  {errors.testType}
                </FormHelperText>
              </FormControl>
            )}

            {showInOfficeTypes && (
              <FormControl
                fullWidth
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Select
                  id="mail-type"
                  name="InOfficeTestTypes"
                  className={`sx-input ${
                    errors && errors.testType && "sx-validationFailed"
                  }`}
                  disableUnderline={true}
                  value={waterTestingDataRequest.InOfficeTestTypes}
                  onChange={handleInOfficeTestTypeChange}
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    Select In Office Type *
                  </MenuItem>
                  {InOfficeTestTypes.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText error className="error-text font-10 text-left">
                  {errors.testType}
                </FormHelperText>
              </FormControl>
            )}

            {displayTestingLab && (
              <TextField
                className="sx-inputGroup my-2"
                fullWidth
                id="testLabName"
                name="TestLabName"
                label="Other"
                onChange={(e) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    TestLabName: e.target.value as string,
                  });
                }}
                value={waterTestingDataRequest.TestLabName}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}

            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-type"
                className={`sx-input ${
                  errors && errors.testType && "sx-validationFailed"
                }`}
                name="RecordType"
                disableUnderline={true}
                disabled
                value={waterTestingDataRequest.RecordType}
                onChange={(e: any) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    RecordType: e.target.value,
                  });
                }}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.recordType}
                </MenuItem>
                {WaterTestingRecordType.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.recordType}
              </FormHelperText>
              {waterTestingDataRequest.RecordType == "1"  && (
                 <Typography
                 variant="body2"
                 color="textSecondary"
                 style={{ textAlign: "center" }}
               >
                 <a
                   href="#"
                   onClick={(e) => {
                     e.preventDefault();
                     handleClose();
                   }}
                 >
                   Please create a retest from the water testing grid, if the
                   sample result is failed
                 </a>
               </Typography>
              )}
            </FormControl>



            <FormControl className="sx-inputGroup my-2">
              <Box className="sx-input">
                <FormLabel className="sx-input-label p-3">
                  Device or Source Water *
                </FormLabel>
                <FormGroup  
                 className={`p-3 tw-bg-white tw-rounded-b-xl ${
                  errors && errors.deviceorSourceWater && "sx-validationFailed"
                }`}
                >
                  {DeviceorSourceWaterHeader.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={(
                            waterTestingDataRequest.DeviceOrSourceWater || []
                          ).includes(item.id)}
                          onChange={(e) =>
                            handleDeviceorSourceWater(e, item.id)
                          }
                          name={item.name}
                          color="primary"
                        />
                      }
                      label={item.name}
                      className={`sx-checkbox-label ${
                        errors && errors.deviceorSourceWater && "sx-validationFailed"
                      }`} 
                    />
                  ))}
                </FormGroup>
                {showDeviceRequired && (
                  <div className="error-text font-10 pl-4 text-left">
                    Required
                  </div>
                )}
              </Box>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.deviceorSourceWater}
              </FormHelperText>
            </FormControl>

            <FormControl
              component="fieldset"
              variant="filled"              
              className={`sx-inputGroup sx-customSelect my-2t ${
                errors && errors.sampleFailed && "sx-validationFailed"
              }`}
            >
              <FormLabel component="legend">Sample Result *</FormLabel>
              <RadioGroup
                aria-label="SampleFailed"
                name="SampleFailed"
                value={waterTestingDataRequest.SampleFailed}
                onChange={(e) => handleSampleFailedStatus(e)}
                className={`sx-inputGroup sx-customSelect my-2t ${
                  errors && errors.sampleFailed && "sx-validationFailed"
                }`}
              >
                {SampleResults.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id.toString()}
                    control={<Radio />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
              {showSampleRequired && (
        <div className="error-text font-10 pl-4 text-left">
          Required
        </div>              
        )}
              {/* <FormHelperText error>{errors.sampleFailed}</FormHelperText> */}
            </FormControl>

            <FormControl fullWidth className="sx-inputGroup my-2 !tw-pt-[22px]">
              <Button
                variant="contained"
                className="MuiButtonBase-root MuiButton-root MuiButton-contained ml-2 sx-button p-0"
                component="label"
              >
                {translation.uploadImage}
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={(e) => handleFilesAdded(e)}
                  hidden
                />
              </Button>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ textAlign: "center" }}
              >
                {translation.takePhotoOfTestResults}
              </Typography>
              {files && (
                <Typography className="small">{files[0].name}</Typography>
              )}
              <FormHelperText error className="helper-text">
                {fileSizeError}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth className="sx-inputGroup my-2 !tw-pt-[22px]">
              <textarea
                id="additionalnotes"
                name="AdditionalNotes"
                onChange={(e: any) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    AdditionalNotes: e.target.value,
                  });
                }}
                value={waterTestingDataRequest.AdditionalNotes}
                className="sx-input"
                placeholder="Additional Notes"
              />
            </FormControl>
            {isOneOrMoreSamplesFail && (
              <FormControl
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Typography>{translation.requestOrder}</Typography>
                <Select
                  id="select-status"
                  label={translation.select}
                  className="sx-input"
                  name="RequestOrder"
                  disableUnderline={true}
                  disabled={readOnlyMode}
                  value={waterTestingDataRequest.RequestOrder}
                  onChange={(e: any) => {
                    setWaterTestingDataRequest({
                      ...waterTestingDataRequest,
                      RequestOrder: e.target.value,
                    });
                  }}
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    {translation.select}
                  </MenuItem>
                  {YesOrNoOptions.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            )}

            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Typography>{translation.requestContact}</Typography>
              <Select
                id="RequestContact"
                name="RequestContact"
                className="sx-input"
                disableUnderline={true}
                disabled={readOnlyMode}
                value={waterTestingDataRequest.RequestContact}
                onChange={(e: any) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    RequestContact: e.target.value,
                  });
                }}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.select}
                </MenuItem>
                {YesOrNoOptions.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        className="ml-2 sx-button p-0"
        onClick={handleAddNew}
      >
        <Add />
        <span className="sx-hideOnMobile">{translation.add}</span>
      </Button>
    </Box>
  );
};

export default connector(AddWaterTesting);
